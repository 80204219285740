import {React} from "react";
import Accordion from "react-bootstrap/Accordion";
import {AiOutlinePlus, AiOutlineClose} from "react-icons/ai";

import "../../scss/partials/filter.scss";

function showFilter(e) {
	e.preventDefault();
	document.querySelector("#root").classList.toggle("show-filter");
}

const Filter = (props) => {

	let filter_one = JSON.parse(props.data.fieldData.GLOBALFILTER1);
	let filter_two = JSON.parse(props.data.fieldData.GLOBALFILTER2);
	let filter_three = JSON.parse(props.data.fieldData.GLOBALFILTER3);
	let filter_four = JSON.parse(props.data.fieldData.GLOBALFILTER4);
	let filter_five = JSON.parse(props.data.fieldData.GLOBALFILTER5);
	let filter_six = JSON.parse(props.data.fieldData.GLOBALFILTER6);

	return (
		<div className="filter col-7  col-md-5 col-lg-4 col-xl-3">
			<div className="w-100 d-flex py-4 align-items-center">
				<a href={() => false} onClick={showFilter} className="text-white closeBtn">
					<AiOutlineClose />
				</a>
				<h2 className="text-center text-white h2">filter results</h2>
			</div>
		
			<Accordion>
			{filter_one.length > 1 ?
				<Accordion.Item eventKey="0">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="pl-4 text-white h2">category</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_one.length > 1
								? filter_one.map((item, index) => (
									
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-1-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="category"
											>	
												{item.value}
											</a>
										</li>
								

								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
				: ""}
		
				{filter_two.length > 1 ? 
				<Accordion.Item eventKey="1">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="text-center text-white h2">collection</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_two.length > 1
								? filter_two.map((item, index) => (
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-2-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="collection"
											>
												{item.value}
											</a>
										</li>
								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
					: ""}
				{filter_three.length > 1 ?
				<Accordion.Item eventKey="2">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="text-center text-white h2">person</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_three.length > 1
								? filter_three.map((item, index) => (
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-3-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="person"
											>
												{item.value}
											</a>
										</li>
								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
				: ""}
				{filter_four.length > 1 ?
				<Accordion.Item eventKey="3">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="text-center text-white h2">place of origin</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_four.length > 1
								? filter_four.map((item, index) => (
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-4-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="place"
											>
												{item.value}
											</a>
										</li>
								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
				: ""}
				{filter_five.length > 1 ?
				<Accordion.Item eventKey="4">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="text-center text-white h2">medium</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_five.length > 1
								? filter_five.map((item, index) => (
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-5-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="medium"
											>
												{item.value}
											</a>
										</li>
								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
					: ""}
				{filter_six.length > 1 ? 
				<Accordion.Item eventKey="5">
					<Accordion.Header>
						<AiOutlinePlus /> <span className="text-center text-white h2">date</span>
					</Accordion.Header>
					<Accordion.Body>
						<ul className="list-unstyled ">
							{filter_six.length > 1
								? filter_six.map((item, index) => (
										<li key={index} className="pb-2">
											<a
												className="text-white "
												href={() => false}
												id={`filter-5-${index}`}
												onClick={props.event}
												data-filter={item.value}
												data-type="date"
											>
												{item.value}
											</a>
										</li>
								  ))
								: ""}
						</ul>
					</Accordion.Body>
				</Accordion.Item>
				: ""}
			</Accordion>
		</div>
	);
};

export default Filter;
