import React from "react";
import ReactDOM from "react-dom";
import Helmet from "react-helmet";
import {BrowserRouter} from "react-router-dom";
import "./scss/style.scss";
import Login from "./components/Login";
import Routes from "./routes";
// import List from "./api/Lists";
// import ListObjects from "./api/ListObjects";
import Header from "./components/Header";
import Footer from "./components/Footer";
import useToken from "./api/hooks/useToken";
import reportWebVitals from "./reportWebVitals";

export default function App() {
	const {token, setToken} = useToken();

	if (!token) {
		return <Login setToken={setToken} />;
	} else {
		return (
			<BrowserRouter>
				<div>
					<Header />
					<Helmet titleTemplate="AMC - Explore the Collection | %s" defaultTitle="AMC - Explore the Collection">
						<meta charSet="utf-8" />
						<meta name="description" content="" />
					</Helmet>
					<Routes />

					<Footer />
				</div>
			</BrowserRouter>
		);
	}
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
