import React from "react";
import "../scss/partials/footer.scss";

class Footer extends React.Component {
	render() {
		return (
			<footer className="container-full footer ">
				{/* <div className="container">
					<div className="row">
						<div className="col-12 text-center">
							<p className="m-0">&copy;{new Date().getFullYear()} Archive MC</p>
							<p className="m-0">
								Made with <span>love</span> by Studio Artioli
							</p>
						</div>
					</div>
				</div> */}
			</footer>
		);
	}
}
export default Footer;
