function Collection(token) {
	const tokenString = token;
	const userToken = JSON.parse(tokenString);

	let raw = JSON.stringify({
		auth: userToken.token,
	});

	const Headers = {
		"Content-Type": "application/json",
		credentials: "include",
		Accept: "application/json",
		SameSite: "None",
		// Authorization: "Bearer " + userToken.token,
	};
	var requestOptions = {
		method: "POST",
		headers: Headers,
		redirect: "follow",
		body: raw,
	};

	return fetch("/restAPI/collection.php", requestOptions);
}

export default Collection;
