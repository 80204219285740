import React from "react";
import {IoGridOutline, IoFilterOutline} from "react-icons/io5";
import {TfiLayoutAccordionSeparated} from "react-icons/tfi";
import "../../scss/partials/icon-bar.scss";

class IconBar extends React.Component {
	constructor(props) {
		super(props);
		this.showCards = this.showCards.bind(this);
		this.showAccordion = this.showAccordion.bind(this);
		this.showFilter = this.showFilter.bind(this);
		this.root = document.getElementById("root");
		this.root.classList.remove("show-list");
		this.root.classList.add("show-cards");
	}

	showCards(e) {
		e.preventDefault();

		this.root.classList.remove("show-list");
		this.root.classList.add("show-cards");
	}
	showAccordion(e) {
		e.preventDefault();

		this.root.classList.remove("show-cards");
		this.root.classList.add("show-list");
	}

	showFilter(e) {
		e.preventDefault();
		this.root.classList.toggle("show-filter");
	}

	render() {
		return (
			<div className="row">
				<ul className="list-unstyled d-flex justify-content-start mb-0 px-2 col-1 ">
					<li className="px-2">
						{this.props.filter === true ? (
							<button type="button" onClick={this.showFilter} className="grid icon-bar-icons">
								<IoFilterOutline />
							</button>
						) : (
							""
						)}
					</li>
				</ul>
				<div className="mb-0 px-2 col-8 d-flex align-items-center ">
					{this.props.error ? (
						<p className="mb-0 error">{this.props.error}</p>
					) : (
						""
					)}
					{this.props.amount || this.props.message  ? (
				
						<p className="mb-0 message">
							{this.props.amount}
							{this.props.message ? " - " + this.props.message : ""}
						</p>
						
					) : (
						""
					)}
				</div>
				<ul className="list-unstyled d-flex justify-content-end mb-0 px-2 col-3">
					<li className="px-2">
						<button type="button" onClick={this.showCards} className="grid icon-bar-icons">
							<IoGridOutline />
						</button>
					</li>
					<li className="px-2">
						<button type="button" onClick={this.showAccordion} className="list icon-bar-icons">
							<TfiLayoutAccordionSeparated />
						</button>
					</li>
				</ul>
			</div>
		);
	}
}
export default IconBar;
