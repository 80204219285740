import React from "react";
import {Link} from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import {withRouter} from "react-router";
import Lists from "../api/Lists";
import Collection from "../api/Collection";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner, faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import "../scss/partials/header.scss";

class Header extends React.Component {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleCollection = this.handleCollection.bind(this);	

		this.state = {
			lists: [],
			collections: [],
			searchParam: "",
			loading: true,
			visible: 'd-block'
		};
	}

	handleClick = async () => {
		await Lists(sessionStorage.getItem("token"), sessionStorage.getItem("user"))
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code !== "0") {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				} else {
					this.setState({
						lists: result.response.data,
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};

	handleCollection = async () => {
		await Collection(sessionStorage.getItem("token"))
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code !== "0") {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				} else {
					this.setState({
						collections: result.response.valueLists[0].values,
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	};


	render() {

		const acquisitionClass = window.location.pathname === "/" ? "active nav-link text-uppercase " : "nav-link text-uppercase ";
		const collectionClass = window.location.pathname.match(/^\/collection/) ? "active px-2 text-uppercase nav-dropdown-collection" : "px-2 text-uppercase nav-dropdown-collection";
		const listClass = window.location.pathname.match(/^\/list/) ? "active px-2 text-uppercase" : "px-2 text-uppercase";

		return (
			<section className="header container-fluid position-fixed px-0">
				<div className="row pb-2 justify-content-center align-items-center">
					<div className="col-6 pd-flex py-2">
						<Link to="/">
							<div className="px-2">
								<Navbar.Brand href="/">Archive of Modern Conflict</Navbar.Brand>
							</div>
						</Link>
					</div>
					<div className="col-6">
						<nav className="navbar navbar-expand-lg navbar-dark  py-2">
						<button
							className="navbar-toggler"
							type="button"
							data-toggle="collapse"
							data-target="#navbarNavDropdown"
							aria-controls="navbarNavDropdown"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="collapse navbar-collapse container" id="navbarNavDropdown">
							<ul className="navbar-nav mx-auto text-center d-flex justify-content-between">
								<li className="nav-item px-2">
							
									<Link to="/" className={acquisitionClass} data-active={this.state.item1}>
										Acquisitions
									</Link>
								
								</li>
			
									<NavDropdown
										title="Collections"
										menuVariant="light"
										id="nav-dropdown-collection"
										className={collectionClass}
										data-active={this.state.item2}
										onClick={this.handleCollection}
									>
			
									{this.state.collections ? (
										<ul className="list-items list-unstyled px-4 d-flex flex-wrap align-items-start">
											{this.state.loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}
											{this.state.collections.map((collection, index) => (
												<li className="d-flex flex-wrap" key={index}>
													<NavDropdown.Item as={Link} to={"/collection/" + collection.value} eventKey={index} className="text-capitalize">
														{collection.value}
													</NavDropdown.Item>
												</li>
											))}
										</ul>
									) : (
										<ul className="list-items list-unstyled px-4 d-flex flex-wrap align-items-start">
											<li className="d-flex flex-wrap">
												<p>No Collections available.</p>
											</li>
										</ul>
									)}
								</NavDropdown>
						
									<NavDropdown title="Lists" menuVariant="light" id="nav-dropdown" className={listClass} onClick={this.handleClick} data-active={this.state.item3}>
						

									{this.state.lists ? (
										<ul className="list-items list-unstyled px-4 d-flex flex-wrap align-items-start">
											{this.state.loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}
											{this.state.lists.map((list, index) => (
												<li className="d-flex flex-wrap" key={index}>
													<NavDropdown.Item as={Link} to={"/list/" + list.fieldData.UUID} eventKey={list.recordId + "-" + index} className="text-capitalize">
															{list.fieldData.listName}
														</NavDropdown.Item>
													</li>
												))}
											</ul>
										) : (
											<ul className="list-items list-unstyled px-4 d-flex flex-wrap align-items-start">
												<li className="d-flex flex-wrap">
													<p>No lists available.</p>
												</li>
											</ul>
										)}
									</NavDropdown>
								</ul>
							</div>
						</nav>
					</div>
					<div className="col-12 py-2">
						<Form className="d-flex search_form position-relative">
							<Form.Control
								type="text"
								placeholder="Search..."
								id="search_input"
								className="me-0 mx-2 "
								aria-label="Search"
								name="search"
								onChange={(e) =>
									this.setState({
										searchParam: e.target.value.replace(" ", "+")
									})
								}
							/>
							<Link to={`/search/?s=${this.state.searchParam}`}>
								<Button variant="" type="submit" className="text-uppercase position-absolute submit-btn">
									<FontAwesomeIcon icon={faMagnifyingGlass} />
								</Button>
							</Link>
						</Form>
					</div>
				</div>

				
			</section>
		);
	}
}

export default withRouter(Header);
