import React from "react";
import Cards from "./partials/Cards";
import Accordions from "./partials/Accordion";
import IconBar from "./partials/IconBar";
import Acquisitions from "../api/Acquisitions";
import "../scss/partials/cards.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

class Acquisition extends React.Component {
	limit = 6;
	constructor(props) {
		super(props);

		this.state = {
			data: [],
			loading: true,
			filterOn: false,
			offset: 1,
			limit: 30,
		};

		this.handleScroll = this.handleScroll.bind(this);
	}
	handleScroll() {
		if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
			this.setState({
				loading: true,
			});
			let offset = this.state.offset + this.state.limit;
			Acquisitions.fetchAcquisitions(offset, this.state.limit)
				.then((result) => result.json())
				.then((result) => {
					if (result.messages[0].code !== "0") {
						sessionStorage.removeItem("token");
						sessionStorage.removeItem("user");
						window.location.reload();
					} else {
						this.setState({
							data: [...this.state.data, ...result.response.data],
							loading: false,
							offset: offset,
						});
					}
				})
				.catch((error) => console.log("error", error));
		}
	}

	componentDidMount() {
		Acquisitions.fetchAcquisitions(this.state.offset, this.state.limit)
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code !== "0") {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				} else {
					this.setState({
						data: result.response.data,
						loading: false,
						offset: this.state.offset,
					});
				}
			})
			.catch((error) => console.log("error", error));

		window.addEventListener("scroll", this.handleScroll);
	}

	componentWillUnmount() {
		window.removeEventListener("scroll", this.handleScroll);
	}

	render() {

		return (
			<div>
				<div className="container-fluid top_bar-after-header">
					<div className="row border-bottom  px-0 py-2">
					<IconBar filter={this.state.filterOn} />
					</div>
				</div>

				<div className="container-fluid ">
					<div className="container data-wrapper py-5">
						{this.state.loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}

						<div className="row cards-wrapper">
							{this.state.data
								? this.state.data.map((data, index) => <Cards key={index} currentPage={window.location.pathname} items={this.state.data} data={data} index={index} />)
								: ""}
						</div>

						<div className="row accordion-wrapper">
							{this.state.data
								? this.state.data.map((data, index) => (
										<Accordions key={index} currentPage={window.location.pathname} items={this.state.data} data={data} index={index} />
								))
								: ""}
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default Acquisition;
