import React from "react";
import Details from "./partials/Details";
import Item from "../api/Item";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

class SingleItem extends React.Component {
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.pagination = [];
		this.state = {
			data: null,
			loading: true,
		};
	}

	componentDidMount() {
		this._isMounted = true;
		this.Single(this.props.match.params.id);
		if (this.props.location.items) {
			sessionStorage.setItem("pagination", JSON.stringify(this.props.location.items));
			this.pagination = sessionStorage.getItem("pagination");
			this.pagination = JSON.parse(this.pagination);
		}

		
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.id !== this.props.match.params.id && this.state.loading === false) {
			this.setState({loading: true});
			this.Single(this.props.match.params.id);
	
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
	}
	Single(id) {
		Item.fetchItem(id)
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code !== "0") {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				} else {
	
					this.setState({
						data: result.response.data,
						loading: false,
					});
				}
			})
			.catch((error) => console.log("error", error));
	}
	render() {
	
		return (
			<div className="container-fluid data-wrapper single border-top pt-1">
				{this.state.loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}
				{this.state.data
					? this.state.data.map(
							(data, index) => (
			
								(
									<Details
										key={index}
										data={data}
										currentPage={this.props.location.currentPage}
										pagination={this.pagination ? this.pagination :''}
										indexKey={this.props.location.itemIndex}
									/>
								)
							)
					  )
					: ""}
			</div>
		);
	}
}
export default SingleItem;
