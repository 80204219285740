const Acquisitions = {
	fetchAcquisitions: (offset, limit) => {
		const tokenString = sessionStorage.getItem("token");
		const userToken = JSON.parse(tokenString);

		let raw = JSON.stringify({
			limit: limit,
			offset: offset,
			token: userToken.token,
		});

		let requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				credentials: "include",
				Accept: "application/json",
				"Access-Control-Allow-Credentials": true,
				"x-content-type-options": "nosniff",
				cookie: {sameSite: "none"},
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
				Authorization: "Bearer " + userToken.token,
			},

			body: raw,
			redirect: "follow",
		};

		return fetch("/restAPI/acquisitions.php", requestOptions);
	},
};

export default Acquisitions;
