import React from "react";
import CardsSearch from "./partials/CardsSearch";
import AccordionsSearch from "./partials/AccordionsSearch";
import IconBar from "./partials/IconBar";
import Filter from "./partials/Filter";
import Filters from "../api/Filters";
import CollectionItem from "../api/CollectionItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

class SingleCollection extends React.Component {
	limit = 6;
	constructor(props) {
		super(props);
		this._isMounted = false;
		this.pagination = null;
		this.clickFilter = this.clickFilter.bind(this);
		this.root = document.getElementById("root");
		this.state = {
			data: null,
			dataFilter: [],
			filter: [],
			filterOn: true,
			message: null,
			loading: true,
			offset: 1,
			limit: 100,
		};
		this.handleScroll = this.handleScroll.bind(this);
	}
	handleScroll() {
		if (window.innerHeight + document.documentElement.scrollTop === document.scrollingElement.scrollHeight) {
			this.setState({
				loading: true,
			});
			let offset = this.state.offset + this.state.limit;
			CollectionItem.fetchItem(this.props.match.params.id, offset, this.state.limit)
				.then((result) => result.json())
				.then((result) => {
					if (result.messages[0].code !== "0") {
						sessionStorage.removeItem("token");
						sessionStorage.removeItem("user");
						window.location.reload();
					} else {
						this.setState({
							data: [...this.state.data, ...result.response.data],
							dataFilter: [...this.state.data, ...result.response.data],
							amount: this.state.amount ? this.state.amount : "",
							loading: false,
							offset: offset,
						});
						this.Filter();
					}
				})
				.catch((error) => console.log("error", error));
		}
	}

	componentDidMount() {
		this._isMounted = true;

		if (this.props.location.items) {
			sessionStorage.setItem("pagination", JSON.stringify(this.props.location.items));
			this.pagination = sessionStorage.getItem("pagination");
			this.pagination = JSON.parse(this.pagination);
		}
		this.Collections(this.props.match.params.id, this.state.offset, this.state.limit);
		window.addEventListener("scroll", this.handleScroll);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.match.params.id !== this.props.match.params.id && this.state.loading === false) {
			this.setState({loading: true, offset: 1});
			this.Collections(this.props.match.params.id, this.state.offset, this.state.limit);
		}
	}
	componentWillUnmount() {
		this._isMounted = false;
		window.removeEventListener("scroll", this.handleScroll);
	}

	Collections(id, offset, limit) {
		CollectionItem.fetchItem(id, offset, limit)
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code !== "0") {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				} else {
					this.setState({
						data: result.response.data,
						dataFilter: result.response.data,
						loading: false,
						amount: result.response.dataInfo.foundCount + " results for collection " + id,
					});
					this.Filter();
				}
			})
			.catch((error) => console.log("error", error));
	}
	Filter() {
		Filters.fetchFilters()
			.then((result) => result.json())
			.then((result) => {
				if (result.messages[0].code === "401") {
					this.setState({
						filter: "",
						loading: false,
					});
				} else if (result.messages[0].code === "0") {
					this.setState({
						filter: result.response.data,
						loading: false,
					});
					if (this.state.data) {
						sessionStorage.setItem("pagination", JSON.stringify(this.state.data));
						this.pagination = sessionStorage.getItem("pagination");
						this.pagination = JSON.parse(this.pagination);
					}
				} else {
					sessionStorage.removeItem("token");
					sessionStorage.removeItem("user");
					window.location.reload();
				}
			})
			.catch((error) => console.log("error", error));
	}
	CheckIfContains(str, words) {
		if (words[0] !== null || words[0] !== " " || words[0] !== "") {
			let regex = new RegExp("\\b(" + words.slice(1).join("|") + ")\\b", "g");
			return regex.test(str);
		} else {
			let regex = new RegExp("\\b(" + words.join("|") + ")\\b", "g");
			return regex.test(str);
		}
	}
	OnlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}

	clickFilter(e) {
		e.preventDefault();
		this.setState({
			loading: true,
		});
		let filter_value = e.target.dataset.filter;
		let filter_type = e.target.dataset.type;

		let filterArray = filter_value.split(" ");

		let data = this.state.dataFilter;
		const allEntries = [];
		Object.keys(data).forEach(function (key) {
			allEntries.push(data[key]);
		});

		let filtering = [];
		// this is still not working properly
		for (let key in allEntries) {
			if (filter_type === "collection") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["collection"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}

			if (filter_type === "place") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["placeDisplay_LU"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}
			if (filter_type === "medium") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["mediumDisplay"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}
			if (filter_type === "person") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["primaryConstituent_LU"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}
			if (filter_type === "category") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["category"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}

			if (filter_type === "date") {
				if (this.CheckIfContains(allEntries[key]["fieldData"]["originYearRange"], filterArray) === true) {
					filtering.push(allEntries[key]);
				}
			}
		}
		let unique = filtering.filter(this.OnlyUnique);

		if (unique.length > 0) {
			sessionStorage.setItem("pagination", JSON.stringify(unique));
			this.pagination = sessionStorage.getItem("pagination");
			this.pagination = JSON.parse(this.pagination);

			this.setState({
				data: unique,
				message: "Filter " + filter_value,
				loading: false,
			});
		} else {
			this.setState({
				data: [],
				message: "No resuls found for " + filter_value,
				loading: false,
			});
		}

		this.root.classList.remove("show-filter");
	}
	render() {
		return (
			<div>
				<div className="container-fluid top_bar-after-header">
				{this.state.filter ? this.state.filter.map((data, index) => <Filter key={index} data={data} event={this.clickFilter} />) : ""}
					<div className="row border-bottom px-0 py-2">
						<div className="col-12 ">
							<IconBar filter={this.state.filterOn} message={this.state.message} amount={this.state.amount} />
						</div>
					</div>
				</div>
				<div className="container-fluid ">
					<div className="container data-wrapper py-5">
						<div className="row">
							{this.state.loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}
							<div className="row cards-wrapper">
								{this.state.data
									? this.state.data.map((data, index) => (
											<CardsSearch key={index} items={this.state.data} data={data} index={index} currentPage={window.location.pathname} />
									))
									: ""}
							</div>
							<div className="row accordion-wrapper">
								{this.state.data
									? this.state.data.map((data, index) => (
											<AccordionsSearch key={index} items={this.state.data} data={data} index={index} currentPage={window.location.pathname} />
									))
									: ""}
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}
export default SingleCollection;
