const CollectionItem = {
	fetchItem: (id, offset, limit) => {
		const tokenString = sessionStorage.getItem("token");
		const userToken = JSON.parse(tokenString);
		var raw = JSON.stringify({
			id: id,
			auth: userToken.token,
			limit: limit,
			offset: offset,
			script: "getFilterData",
		});

		let requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				withCredentials: true,
				Accept: "application/json",
				"x-content-type-options": "nosniff",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
				// Authorization: "Bearer " + userToken.token,
			},

			body: raw,
			redirect: "follow",
		};

		return fetch("/restAPI/collectionItem.php", requestOptions);
	},
};

export default CollectionItem;
