function Lists(token, user) {
	const tokenString = token;
	const userToken = JSON.parse(tokenString);

	let raw = JSON.stringify({
		username: user,
		auth: userToken.token,
		script: "getFilterData",
	});

	const Headers = {
		"Content-Type": "application/json",
		credentials: "include",
		Accept: "application/json",
		SameSite: "None",
		// Authorization: "Bearer " + userToken.token,
	};
	let requestOptions = {
		method: "POST",
		headers: Headers,
		body: raw,
		redirect: "follow",
	};

	return fetch("/restAPI/user_list.php", requestOptions);
}

export default Lists;
