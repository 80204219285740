import React from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import camera from'../../images/a102_cameraOutline.png';
import "../../scss/partials/cards.scss";

const CardsSearch = (props) => {

	return (
		<Card className="col-12 col-md-3 mb-4 position-relative">
			{(() => {
				{
					if (props.data.fieldData.objectID) {
						return (
							<Link
								className="position-relative"
								to={{
									pathname: "/item/" + props.data.recordId,
									items: props.items,
									itemIndex: props.index,
									currentPage: props.currentPage,
								}}
							>
								<div className="position-relative">
									<div className="position-relative img-wrap overflow-hidden">
										{props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"] ? (
											<Card.Img
												variant="top"
												src={props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"]}
												referrerPolicy="no-referrer-when-downgrade"
											/>
										) : (
											<Card.Img variant="top"  src={camera}  referrerPolicy="no-referrer-when-downgrade" />
										)}
									</div>
									<Card.ImgOverlay>
										<Card.Title className="pb-2">{props.data.fieldData.objectID}</Card.Title>
										<Card.Text className="mb-1">{props.data.fieldData["Purchases_API::primaryConstituent_LU"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.fieldData.primaryTitle}</Card.Text>
										<Card.Text className="mb-1">{props.data.fieldData["Purchases_API::inscription"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.fieldData["Purchases_API::originDateDisplay"]}</Card.Text>
									</Card.ImgOverlay>
								</div>
								<Card.Body>
									<Card.Title>{props.data.fieldData.objectID}</Card.Title>
								</Card.Body>
							</Link>
						);
						} 
				}
			})()}
		</Card>
	);
};

export default CardsSearch;
