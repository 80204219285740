import React from "react";
import {Route, Switch} from "react-router-dom";
import Login from "./components/Login";
import useToken from "./api/hooks/useToken";
import Acquisition from "./components/Acquisition";
import Search from "./components/Search";
import SingleItem from "./components/SingleItem";
import SingleCollection from "./components/SingleCollection";
import List from "./components/List";

export default function Routes() {
	let _path = "/";
	const {token, setToken} = useToken();

	if (!token) {
		return <Login setToken={setToken} />;
	} else {
		return (
			<Switch>
				<Route exact path={`${_path}`} component={Acquisition} />
				<Route exact path={`${_path}search/`} component={Search} />
				<Route exact path={`${_path}item/:id`} component={SingleItem} />
				<Route exact path={`${_path}list/:id`} component={List} />
				<Route exact path={`${_path}collection/:id`} component={SingleCollection} />
			</Switch>
		);
	}
}
