import React from "react";
import Pagination from "./Pagination";

const Details = (props) => {
	
	if (props && props !== undefined) {
		return (
			<div className="row h-100">
				<div className="col-12 col-md-7 image-wrapper py-5 ">
					{props.data.portalData.Media_API
						? props.data.portalData.Media_API.map((data, index) => (
								<div className="img-wrap mb-4 d-flex align-items-center">
									<img
										key={index}
										alt={props.data.fieldData.primaryTitle + "-" + index}
										className="d-block mx-auto"
										src={data["Media_API::derivativeScreen"]}
									/>
								</div>
						  ))
						: ""}
				</div>

				<div className="col-12 col-md-5 border-left">
					<div className="w-100  border-bottom">{props.pagination.length > 0 ? <Pagination data={props.pagination} indexKey={props.indexKey} currentPage={props.currentPage} /> : ""}</div>

					<ul className="list-unstyled py-3 px-2 content-details">
						<li className="pb-1">
							<p>
								<strong>Object ID: </strong>
								{props.data.fieldData.objectID}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Artist/Maker: </strong>
								{props.data.fieldData.primaryConstituent_LU}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Title: </strong>
								{props.data.fieldData.primaryTitle}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Date of Origin: </strong>
								{props.data.fieldData.originDateDisplay}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Place of Origin: </strong>
								{props.data.fieldData.placeSearch_LU}
							</p>
						</li>

						<li className="pb-1">
							<p>
								<strong>Medium: </strong>
								{props.data.fieldData.mediumDisplay}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Location: </strong>
								{props.data.fieldData.location_LU}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Category: </strong>
								{props.data.fieldData.category}
							</p>
						</li>
						<li className="pb-1">
							<p>
								<strong>Collection: </strong>
								{props.data.fieldData.collection}
							</p>
						</li>
					</ul>
				</div>
			</div>
		);
	} else return null;
};

export default Details;
