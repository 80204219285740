const Item = {
	fetchItem: (id) => {
		const tokenString = sessionStorage.getItem("token");
		const userToken = JSON.parse(tokenString);
		let raw = JSON.stringify({
			auth: userToken.token,
			id: id,
		});
		let requestOptions = {
			method: "POST",
			auth: userToken.token,
			body: raw,
			headers: {
				"Content-Type": "application/json",
				withCredentials: true,
				Accept: "application/json",
				"x-content-type-options": "nosniff",
				"x-xss-protection": "1; mode=block",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
				Authorization: "Bearer " + userToken.token,
			},

			redirect: "follow",
		};

		return fetch("/restAPI/item.php", requestOptions);
	},
};

export default Item;
