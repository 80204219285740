const ListObjects = {
	fetchListObj(id, offset, limit) {
		const tokenString = sessionStorage.getItem("token");
		const userToken = JSON.parse(tokenString);
		let raw = JSON.stringify({
			id: id,
			auth: userToken.token,
			limit: limit,
			offset: offset,
			script: "getFilterData",
		});

		let requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				withCredentials: true,
				Accept: "application/json",
				"x-content-type-options": "nosniff",
				"x-xss-protection": "1; mode=block",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
			},
			body: raw,
			redirect: "follow",
		};

		return fetch("/restAPI/user_listObjects.php", requestOptions);
	},
};

export default ListObjects;
