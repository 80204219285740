import {useState, React, useEffect} from "react";
import {Link} from "react-router-dom";
import {HiOutlineChevronRight, HiOutlineChevronLeft} from "react-icons/hi";
import {IoGridOutline} from "react-icons/io5";
import {TfiLayoutAccordionSeparated} from "react-icons/tfi";
import "../../scss/partials/single-top-bar.scss";
import "../../scss/partials/icon-bar.scss";

const Pagination = (props) => {
	const [num, setNum] = useState(props.indexKey);
	const [type, setType] = useState();

	const CounterI = () => {
		setNum(num + 1);
	};
	const CounterD = () => {
		setNum(num - 1);
	};

	const root = document.getElementById("root");

	const showCards = () => {

		setTimeout(function () {
			root.classList.remove("show-list");
			root.classList.add("show-cards");
		}, 500);
	};
	const showAccordion = () => {


		setTimeout(function () {
			root.classList.remove("show-cards");
			root.classList.add("show-list");
		}, 500);
	};

	useEffect(() => {
		if (props.data[num].portalData.Media_API !== undefined) {
			setType("search");
		}else if (props.data[num].portalData === undefined) {
			setType("filtered");
		} else {
			setType("full");
		}
	}, [type]);

	let prev, next;

	if (type === "full") {
		prev = num === 0 ? props.data[num].portalData.Objects_API[0].recordId : props.data[num - 1].portalData.Objects_API[0].recordId;
		next = num < props.data.length - 1 ? props.data[num + 1].portalData.Objects_API[0].recordId : props.data[num].portalData.Objects_API[0].recordId;
	} else if (type === "filtered" || type === "search") {
		prev = num === 0 ? props.data[num].recordId : props.data[num - 1].recordId;
		next = num < props.data.length - 1 ? props.data[num + 1].recordId : props.data[num].recordId;
	}

		return (
			<div>
				{(() => {
					if (props.data && num > 0 && num < props.data.length - 1) {
						return (
							<div className="pagination py-3 d-flex justify-content-between">
								<div className="controls d-flex align-items-center">
									<Link
										onClick={CounterD}
										className="btn-chevron"
										to={{pathname: "/item/" + prev, data: props.data, indexKey: num, currentPage: props.currentPage}}
									>
										<HiOutlineChevronLeft />
									</Link>
									<span className="d-inline-flex">
										{num + 1} of {props.data.length}
									</span>
									<Link
										onClick={CounterI}
										className="btn-chevron"
										to={{pathname: "/item/" + next, data: props.data, indexKey: num, currentPage: props.currentPage}}
									>
										<HiOutlineChevronRight />
									</Link>
								</div>
								<ul className="list-unstyled d-flex justify-content-end mb-0 px-2">
									<li className="px-2">
										<Link type="button" onClick={showCards} to={props.currentPage} className="grid icon-bar-icons">
											<IoGridOutline />
										</Link>
									</li>
									<li className="px-2">
										<Link type="button" onClick={showAccordion} to={props.currentPage} className="list icon-bar-icons">
											<TfiLayoutAccordionSeparated />
										</Link>
									</li>
								</ul>
							</div>
						);
					} else if (props.data && num === 0 && num <= props.data.length - 1 && props.data.length !== 1) {
						return (
							<div className="pagination py-3 d-flex justify-content-between">
								<div className="controls d-flex align-items-center">
									<a href={() => false} id="prev-arrow-disabled" className="btn-chevron disabled">
										<HiOutlineChevronLeft />
									</a>
									<span className="d-inline-flex">
										{num + 1} of {props.data.length}
									</span>

									<Link
										onClick={CounterI}
										className="btn-chevron"
										to={{pathname: "/item/" + next, data: props.data, indexKey: num, currentPage: props.currentPage}}
									>
										<HiOutlineChevronRight />
									</Link>
								</div>
								<ul className="list-unstyled d-flex justify-content-end mb-0 px-2 ">
									<li className="px-2">
										<Link type="button" onClick={showCards} to={props.currentPage} className="grid icon-bar-icons">
											<IoGridOutline />
										</Link>
									</li>
									<li className="px-2">
										<Link type="button" onClick={showAccordion} to={props.currentPage} className="list icon-bar-icons">
											<TfiLayoutAccordionSeparated />
										</Link>
									</li>
								</ul>
							</div>
						);
					} else if (num === props.data.length || props.data.length > 1) {
						return (
							<div className="pagination py-3 d-flex justify-content-between">
								<div className="controls d-flex align-items-center">
									<Link
										onClick={CounterD}
										className="btn-chevron"
										to={{pathname: "/item/" + prev, data: props.data, indexKey: num, currentPage: props.currentPage}}
									>
										<HiOutlineChevronLeft />
									</Link>
									<span className="d-inline-flex">
										{num + 1} of {props.data.length}
									</span>

									<a href={() => false} id="next-arrow-disabled" className="btn-chevron disabled">
										<HiOutlineChevronRight />
									</a>
								</div>
								<ul className="list-unstyled d-flex justify-content-end mb-0 px-2 ">
									<li className="px-2">
										<Link type="button" onClick={showCards} to={props.currentPage} className="grid icon-bar-icons">
											<IoGridOutline />
										</Link>
									</li>
									<li className="px-2">
										<Link type="button" onClick={showAccordion} to={props.currentPage} className="list icon-bar-icons">
											<TfiLayoutAccordionSeparated />
										</Link>
									</li>
								</ul>
							</div>
						);
					} else {
						return (
							<div className="pagination py-3 d-flex justify-content-end">
								<ul className="list-unstyled d-flex justify-content-end mb-0 px-2 ">
									<li className="px-2">
										<Link type="button" onClick={showCards} to={props.currentPage} className="grid icon-bar-icons">
											<IoGridOutline />
										</Link>
									</li>
									<li className="px-2">
										<Link type="button" onClick={showAccordion} to={props.currentPage} className="list icon-bar-icons">
											<TfiLayoutAccordionSeparated />
										</Link>
									</li>
								</ul>
							</div>
						);
					}
				})()}
			</div>
		);
	
};

export default Pagination;
