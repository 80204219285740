import React from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import camera from'../../images/a102_cameraOutline.png';
import "../../scss/partials/accordion.scss";



const AccordionsSearch = (props) => {
	return (
		<Accordion className="mb-3" defaultActiveKey="0">
			{(() => {
				{
				if (props.data.fieldData.objectID) {
				return (
				<Card>
					<div className="w-100">
						<Link
							className="position-relative"
							to={{
								pathname: "/item/" + props.data.recordId,
								items: props.items,
								itemIndex: props.index,
								currentPage: props.currentPage,
							}}
						>
							<Card.Header className=" d-flex align-items-center py-3">

								<div className="col-6 d-flex flex-row align-items-center">
									{props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"] ? (
										<img
											className="accordion-image"
											alt={props.data.fieldData.primaryTitle}
											src={props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"]}
											referrerPolicy="no-referrer-when-downgrade"
										/>
									) : (
										<img
											className="accordion-image"
											alt={props.data["Objects_API::primaryTitle"]}
											src={camera} 
											referrerPolicy="no-referrer-when-downgrade"
										/>
									)}

									<ul className="list-unstyled px-3">
										{props.data.fieldData.objectID? (
											<li>
												<h5 className="pb-2">{props.data.fieldData.objectID}</h5>
											</li>
										) : (
											""
										)}
										{props.data.fieldData.primaryTitle ? (
											<li>
												<p className="mb-1">{props.data.fieldData.primaryTitle}</p>
											</li>
										) : (
											""
										)}
										{props.data.fieldData["Objects_API::inscription"] ? (
											<li>
												<p className="mb-1">{props.data.fieldData["Objects_API::inscription"]}</p>
											</li>
										) : (
											""
										)}
										{props.data.fieldData["Objects_API::originDateDisplay"] ? (
											<li>
												<p className="mb-1">{props.data.fieldData["Objects_API::originDateDisplay"]}</p>
											</li>
										) : (
											""
										)}
									</ul>
								</div>
							</Card.Header>
						</Link>
					</div>
				</Card>
							);
						} 
				}
			})()}
		</Accordion>
	);
};

export default AccordionsSearch;
