import React, {useState} from "react";
import PropTypes from "prop-types";
import {Buffer} from "buffer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

async function loginUser(credentials) {
	let headers = {
		"Content-Length": "0",
		"Content-Type": "application/json",
		"Access-Control-Allow-Origin": "http://archivemc.local",
		"Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
	};

	let cred = {
		auth: credentials,
	};

	let requestOptions = {
		method: "POST",
		headers: headers,
		body: JSON.stringify(cred),
	};

	return fetch("/restAPI/fetch.php", requestOptions)
		.then((data) => data.json())
		.catch((error) => console.log("error", error));
}

export default function Login({setToken}) {
	const [username, setUserName] = useState();
	const [password, setPassword] = useState();
	const [loading, setLoading] = useState();
	const [erroMsg, setErroMsg] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		sessionStorage.setItem("user", username);
		const user = Buffer.from(username + ":" + password).toString("base64");
		const token = await loginUser(user);

		if (token.messages[0].code && token.messages[0].code === "0") {
			setErroMsg(false);
			setLoading(false);
			setToken(token.response);
		} else {
			setLoading(false);
			setErroMsg(token.messages[0].message);
		}
	};
	return (
		<section className="container login-container">
			{loading === true ? <FontAwesomeIcon icon={faSpinner} pulse /> : ""}
			<div className="row d-flex vh-100 justify-content-center align-items-center">
				<div className="col-12 col-md-8 d-flex justify-content-center align-items-center flex-column">
					<form onSubmit={handleSubmit} className="w-75 position-relative">
						<div className="form-group pb-4">
							<label htmlFor="user_name" className="w-100">
								<p>Username</p>
								<input type="text" id="user_name" className="form-control" placeholder="Username" onChange={(e) => setUserName(e.target.value)} />
							</label>
						</div>
						<div className="form-group pb-4">
							<label htmlFor="pass_word" className="w-100">
								<p>Password</p>
								<input type="password" className="form-control" id="pass_word" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
							</label>
						</div>

						<button type="submit" className="btn btn-primary">
							Submit
						</button>
					</form>

					<p className={erroMsg !== false || erroMsg !== undefined ? "text-danger py-4 text-center" : "text-success py-4 text-center"}>
						{erroMsg !== false || erroMsg !== undefined ? erroMsg : "Success."}
					</p>
				</div>
			</div>
		</section>
	);
}

Login.propTypes = {
	setToken: PropTypes.func.isRequired,
};
