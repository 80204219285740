import React from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import {useAccordionButton} from "react-bootstrap/AccordionButton";
import camera from'../../images/a102_cameraOutline.svg';
import "../../scss/partials/accordion.scss";

function CustomToggle({children, eventKey}) {
	const decoratedOnClick = useAccordionButton(eventKey);

	return (
		<button type="button" className="accordion-custom-button" onClick={decoratedOnClick}>
			{children}
		</button>
	);
}

const Accordions = (props) => {
	return (
		<Accordion className="mb-3" defaultActiveKey="0">
			{props.data.portalData ? (
				<Card>
					<Card.Header className=" d-flex align-items-center">
						{props.data.portalData.Objects_API.length > 1 ? (
							<CustomToggle eventKey={props.data.recordId}></CustomToggle>
						) : (
							<button type="button" className="accordion-custom-button visibility-hidden"></button>
						)}

						<div className="w-100">
							<Link
								className="col-6 d-flex flex-row align-items-center"
								to={{
									pathname: "/item/" + props.data.portalData.Objects_API[0].recordId,
									items: props.items,
									itemIndex: props.index,
									currentPage: props.currentPage,
								}}
							>
								{props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"] ? (
									<img
										className="accordion-image"
										alt={props.data.portalData.Objects_API[0]["Objects_API::primaryTitle"]}
										src={props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"]}
										referrerPolicy="no-referrer-when-downgrade"
									/>
								) : (
									<img className="accordion-image" alt="placeholder"  src={camera}  referrerPolicy="no-referrer-when-downgrade" />
								)}

								<ul className="list-unstyled px-3">
									{props.data.portalData.Objects_API[0]["Objects_API::objectID"] ? (
										<li>
											<h5 className="pb-2">{props.data.portalData.Objects_API[0]["Objects_API::objectID"]}</h5>
										</li>
									) : (
										""
									)}
									{props.data.portalData.Objects_API[0]["Objects_API::primaryTitle"] ? (
										<li>
											<p className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::primaryTitle"]}</p>
										</li>
									) : (
										""
									)}
									{props.data.portalData.Objects_API[0]["Objects_API::inscription"] ? (
										<li>
											<p className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::inscription"]}</p>
										</li>
									) : (
										""
									)}
									{props.data.portalData.Objects_API[0]["Objects_API::originDateDisplay"] ? (
										<li>
											<p className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::originDateDisplay"]}</p>
										</li>
									) : (
										""
									)}
								</ul>
							</Link>
						</div>
					</Card.Header>
					{props.data.portalData.Objects_API.length > 1
						? props.data.portalData.Objects_API.slice(1).map((item, index) => (
								<Accordion.Collapse eventKey={props.data.recordId} key={index}>
									<Card>
										<Link
											className="position-relative"
											to={{
												pathname: "/item/" + props.data.portalData.Objects_API[0].recordId,
												items: props.items,
												itemIndex: props.index,
												currentPage: props.currentPage,
											}}
										>
											<Card.Header className=" d-flex align-items-center py-3">
												<button type="button" className="accordion-custom-button visibility-hidden"></button>

												<div className="col-6 d-flex flex-row align-items-center">
													{item["PrimaryMedia_API::derivativeThumbnail"] ? (
														<img
															className="accordion-image"
															alt={item["Objects_API::primaryTitle"]}
															src={item["PrimaryMedia_API::derivativeThumbnail"]}
															referrerPolicy="no-referrer-when-downgrade"
														/>
													) : (
														<img
															className="accordion-image"
															alt="placeholder"
															src={camera} 
															referrerPolicy="no-referrer-when-downgrade"
														/>
													)}
													<ul className="list-unstyled px-3">
														{item["Objects_API::objectID"] ? (
															<li>
																<h5 className="pb-2">{item["Objects_API::objectID"]}</h5>
															</li>
														) : (
															""
														)}
														{item["Objects_API::primaryTitle"] ? (
															<li>
																<p className="mb-1">{item["Objects_API::primaryTitle"]}</p>
															</li>
														) : (
															""
														)}
														{item["Objects_API::inscription"] ? (
															<li>
																<p className="mb-1">{item["Objects_API::inscription"]}</p>
															</li>
														) : (
															""
														)}
														{item["Objects_API::originDateDisplay"] ? (
															<li>
																<p className="mb-1">{item["Objects_API::originDateDisplay"]}</p>
															</li>
														) : (
															""
														)}
													</ul>
												</div>
											</Card.Header>
										</Link>
									</Card>
								</Accordion.Collapse>
						  ))
						: ""}
				</Card>
			) : (
				<Card>
					<div className="w-100">
						<Link
							className="position-relative"
							to={{
								pathname: "/item/" + props.data.recordId,
								items: props.items,
								itemIndex: props.index,
								currentPage: props.currentPage,
							}}
						>
							<Card.Header className=" d-flex align-items-center py-3">
								<button type="button" className="accordion-custom-button visibility-hidden"></button>

								<div className="col-6 d-flex flex-row align-items-center">
									{props.data["PrimaryMedia_API::derivativeThumbnail"] ? (
										<img
											className="accordion-image"
											alt={props.data["Objects_API::primaryTitle"]}
											src={props.data["PrimaryMedia_API::derivativeThumbnail"]}
											referrerPolicy="no-referrer-when-downgrade"
										/>
									) : (
										<img
											className="accordion-image"
											alt={props.data["Objects_API::primaryTitle"]}
											src={camera} 
											referrerPolicy="no-referrer-when-downgrade"
										/>
									)}

									<ul className="list-unstyled px-3">
										{props.data["Objects_API::objectID"] ? (
											<li>
												<h5 className="pb-2">{props.data["Objects_API::objectID"]}</h5>
											</li>
										) : (
											""
										)}
										{props.data["Objects_API::primaryTitle"] ? (
											<li>
												<p className="mb-1">{props.data["Objects_API::primaryTitle"]}</p>
											</li>
										) : (
											""
										)}
										{props.data["Objects_API::inscription"] ? (
											<li>
												<p className="mb-1">{props.data["Objects_API::inscription"]}</p>
											</li>
										) : (
											""
										)}
										{props.data["Objects_API::originDateDisplay"] ? (
											<li>
												<p className="mb-1">{props.data["Objects_API::originDateDisplay"]}</p>
											</li>
										) : (
											""
										)}
									</ul>
								</div>
							</Card.Header>
						</Link>
					</div>
				</Card>
			)}
		</Accordion>
	);
};

export default Accordions;
