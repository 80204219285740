import React, {useState} from "react";
import {Link} from "react-router-dom";
import Card from "react-bootstrap/Card";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCirclePlus} from "@fortawesome/free-solid-svg-icons";
import "../../scss/partials/cards.scss";
import camera from'../../images/a102_cameraOutline.svg';

const Cards = (props) => {
	const [isActive, setActive] = useState(false);

	const toggleClass = (e) => {
		e.preventDefault();
		setActive(!isActive);
	};
	return (
		<Card className="col-12 col-md-3 mb-4 position-relative">
			{(() => {
				{
					if (props.data["Objects_API::objectID"]) {
						return (
							<Link
								className="position-relative"
								to={{
									pathname: "/item/" + props.data.recordId,
									items: props.items,
									itemIndex: props.index,
									currentPage: props.currentPage,
								}}
							>
								<div className="position-relative">
									<div className="position-relative img-wrap overflow-hidden">
										{props.data["PrimaryMedia_API::derivativeThumbnail"] ? (
											<Card.Img variant="top" src={props.data["PrimaryMedia_API::derivativeThumbnail"]} referrerPolicy="no-referrer-when-downgrade" />
										) : (
											<Card.Img variant="top" src={camera} referrerPolicy="no-referrer-when-downgrade" />
										)}
									</div>
									<Card.ImgOverlay>
										<Card.Title className="pb-2">{props.data["Objects_API::objectID"]}</Card.Title>
										<Card.Text className="mb-1">{props.data["Objects_API::primaryConstituent_LU"]}</Card.Text>
										<Card.Text className="mb-1">{props.data["Objects_API::primaryTitle"]}</Card.Text>
										<Card.Text className="mb-1">{props.data["Objects_API::inscription"]}</Card.Text>
										<Card.Text className="mb-1">{props.data["Objects_API::originDateDisplay"]}</Card.Text>
									</Card.ImgOverlay>
								</div>
								<Card.Body>
									<Card.Title>{props.data["Objects_API::objectID"]}</Card.Title>
								</Card.Body>
							</Link>
						);
					} else if (typeof props.data.portalData.Objects_API !== "undefined" && props.data.portalData.Objects_API.length > 1) {
						return (
							<div>
								<button type="button" className="cards-custom-button " onClick={toggleClass}>
									<span className="position-relative">
										<FontAwesomeIcon icon={faCirclePlus} />
									</span>
								</button>

								<div className="position-relative card-with-children" onClick={toggleClass}>
									<div className="position-relative img-wrap overflow-hidden">
										{props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"] ? (
											<Card.Img
												variant="top"
												src={props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"]}
												referrerPolicy="no-referrer-when-downgrade"
											/>
										) : (
											<Card.Img variant="top" src={camera} referrerPolicy="no-referrer-when-downgrade" />
										)}
									</div>
									<Card.ImgOverlay>
										<Card.Title className="pb-2">{props.data.portalData.Objects_API[0]["Objects_API::objectID"]}</Card.Title>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::primaryConstituent_LU"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::primaryTitle"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::inscription"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::originDateDisplay"]}</Card.Text>
									</Card.ImgOverlay>
								</div>
								<Card.Body>
									<Card.Title>{props.data.portalData.Objects_API[0]["Objects_API::objectID"]}</Card.Title>
								</Card.Body>
								<div className={isActive ? " w-100 position-fixed group-purchase active py-4 px-4 " : "w-0 h-0 position-fixed group-purchase py-4 "}>
									<div className="container">
										<div className="row w-100 row-wrapper">
											<div className={isActive ? " col-12 d-flex justify-content-end py-4 border-bottom mb-4 " : "col-12 d-flex justify-content-end "}>
												<button type="button" className="btn-close" onClick={toggleClass}></button>
											</div>
											{props.data.portalData.Objects_API.map((data, index) => (
												<Card key={index} className="col-12 col-md-3 mb-4 position-relative inner-cards">
													<Link
														className="position-relative"
														to={{pathname: "/item/" + data.recordId, items: props.items, itemIndex: props.index, currentPage: props.currentPage}}
													>
														<div className="position-relative">
															<div className="position-relative img-wrap overflow-hidden">
																{data["PrimaryMedia_API::derivativeThumbnail"] ? (
																	<Card.Img
																		variant="top"
																		src={data["PrimaryMedia_API::derivativeThumbnail"]}
																		referrerPolicy="no-referrer-when-downgrade"
																	/>
																) : (
																	<Card.Img variant="top" src={camera} referrerPolicy="no-referrer-when-downgrade" />
																)}
															</div>
															<Card.ImgOverlay>
																<Card.Title className="pb-2">{data["Objects_API::objectID"]}</Card.Title>
																<Card.Text className="mb-1">{data["Objects_API::primaryTitle"]}</Card.Text>
																<Card.Text className="mb-1">{data["Objects_API::inscription"]}</Card.Text>
																<Card.Text className="mb-1">{data["Objects_API::originDateDisplay"]}</Card.Text>
															</Card.ImgOverlay>
														</div>
														<Card.Body>
															<Card.Title>{data["Objects_API::objectID"]}</Card.Title>
														</Card.Body>
													</Link>
												</Card>
											))}
										</div>
									</div>
								</div>
							</div>
						);
					} else {
						return (
							<Link
								className="position-relative"
								to={{
									pathname: "/item/" + props.data.portalData.Objects_API[0].recordId,
									items: props.items,
									itemIndex: props.index,
									currentPage: props.currentPage,
								}}
							>
								<div className="position-relative">
									<div className="position-relative img-wrap overflow-hidden">
										{props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"] ? (
											<Card.Img
												variant="top"
												src={props.data.fieldData["PrimaryMedia_API::derivativeThumbnail"]}
												referrerPolicy="no-referrer-when-downgrade"
											/>
										) : (
											<Card.Img variant="top" src={camera} referrerPolicy="no-referrer-when-downgrade" />
										)}
									</div>
									<Card.ImgOverlay>
										<Card.Title className="pb-2">{props.data.portalData.Objects_API[0]["Objects_API::objectID"]}</Card.Title>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::primaryTitle"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::inscription"]}</Card.Text>
										<Card.Text className="mb-1">{props.data.portalData.Objects_API[0]["Objects_API::originDateDisplay"]}</Card.Text>
									</Card.ImgOverlay>
								</div>
								<Card.Body>
									<Card.Title>{props.data.portalData.Objects_API[0]["Objects_API::objectID"]}</Card.Title>
								</Card.Body>
							</Link>
						);
					}
				}
			})()}
		</Card>
	);
};

export default Cards;
